<template lang='pug'>
  .c-login.ed-grid.m-grid-6
    .m-cols-3.m-x-1.l-cols-4.l-x-1.c-login__banner
      img(src="@/assets/images/login/banner.svg")
    .m-cols-3.m-x-4.l-cols-2.l-x-5.c-login__access
      img(src="@/assets/images/login/logo.svg")
      validation-observer.c-login-form(
        ref="observer"
        v-slot="{ handleSubmit }"
        tag="form"
        @submit.prevent
      )
        .input-group
          input-text(
            icon="user"
            type="email"
            rules="required|email"
            inputStyle=""
            name="email"
            v-model="username"
            placeholder="Correo electrónico"
            @enter="handleSubmit(onSubmit)"
          )
        .input-group
          input-password(
            icon="lock"
            rules="required"
            inputStyle=""
            name="password"
            v-model="password"
            placeholder="Contraseña"
            @enter="handleSubmit(onSubmit)"
          )
        .input-group
          label.e-checkbox(
            style="color:#006AB6"
          )
            input(
              type="checkbox",
              v-model="saveAuth"
            )
            span
              i.icon-check
            p Recordar cuenta
        button.e-btn.e-btn--rounded.e-btn--fullwidth(
          v-if="!isLoadingAuth"
          @click="handleSubmit(onSubmit)"
        ) Ingresar
        .e-btn.e-btn--rounded.e-btn--fullwidth.e-btn--loading(
          v-else
        )
          i.icon-loading
        a(
          @click="$modal.show('recover-password')"
          style="color:#006AB6"
        ).c-login-form__link ¿Olvidaste tu contraseña?
    modal(
      name="recover-password"
      :width="450"
      height="auto"
      :adaptive="true"
      class="c-modal"
    )
      .c-recover-pass
        i.icon-lock
        p ¿Olvidaste tu contraseña?
          span Ingresa tu correo para recuperarla
        validation-observer.c-login-form.c-login-form--recover(
          ref="observer"
          tag="form"
          @submit.prevent
        )
          .input-group
            input-text(
              icon="user"
              type="email"
              rules="required|email"
              inputStyle="input--gray"
              name="email"
              v-model="emailRecover"
              placeholder="Correo electrónico"
              @enter="sendEmail"
            )
          button.e-btn.e-btn--rounded.e-btn--small(
            v-if="!isLoadingAuth"
            @click="sendEmail"
          ) Recuperar
          .e-btn.e-btn--rounded.e-btn--fullwidth.e-btn--loading(
            v-else
          )
            i.icon-loading
    modal(
      name="recover-password-error"
      :width="450"
      height="auto"
      :adaptive="true"
      class="c-modal"
    )
      .c-recover-pass.p-2
        i.icon-lock.mb-6
        p.mb-6 Lo sentimos, el correo ingresado no coincide con ningún usuario en el sistema.
        .e-btn.e-btn--rounded.e-btn--small(
          style="margin:auto"
          @click="tryAgainRecoverPassword"
        ) Volver a intentar
    modal(
      name="recover-password-successfull"
      :width="450"
      height="auto"
      :adaptive="true"
      class="c-modal"
    )
      .c-recover-pass.p-2
        i.icon-lock.mb-6
        .fw-4.mb-3.text-center.color-text-darker Se ha enviado un mensaje con un enlace de recuperación de contraseña al correo:
        .fz-6.fw-6.mb-3.text-center.color-text-darker {{ emailRecover }}
        .fz-4.fw-3.mb-4.text-center.color-text-darker Ten en cuenta que nuestro mensaje puede llegar a tu bandeja de SPAM
        .e-btn.e-btn--rounded.e-btn--small(
          @click="$modal.hide('recover-password-successfull')"
        ) Aceptar
</template>

<script>
import { mapActions } from 'vuex'
import InputText from '@/components/02-molecule/formElements/default'
import InputPassword from '@/components/02-molecule/formElements/password'

export default {
  components: { InputText, InputPassword },
  data: () => ({
    data: '',
    emailRecover: '',
    username: '',
    errorMessage: '',
    password: '',
    saveAuth: false,
    isLoadingAuth: false
  }),
  created () {
    if (this.$cookies.get('auth')) {
      this.username = this.$cookies.get('auth').username
      this.password = this.$cookies.get('auth').password
    }
  },
  methods: {
    ...mapActions({
      A_LOGIN: 'Auth/A_LOGIN',
      A_SET_EMAIL: 'Email/A_SET_EMAIL'
    }),
    async onSubmit () {
      try {
        this.isLoadingAuth = true
        const response = await this.A_LOGIN({ username: this.username, password: this.password })
        if (response.isCorrectUser) {
          if (this.saveAuth) {
            this.$cookies.set('auth', { username: this.username, password: this.password })
          }
          location.reload()
        } else this.TOAST(response.message, '', response.code)
        this.isLoadingAuth = false
      } catch (error) {
        this.TOAST('Usuario y/o Contraseña Incorrecta', null, 500)
        this.isLoadingAuth = false
        console.error('LOADING PAGE LOGIN', error)
      }
    },
    hideModal (name) {
      this.$modal.hide(name)
    },
    async sendEmail () {
      try {
        this.isLoadingAuth = true
        await this.A_SET_EMAIL({
          serverName: 'institutional',
          email: this.emailRecover
        })
        this.isLoadingAuth = false
        this.$modal.show('recover-password-successfull')
        this.$modal.hide('recover-password')
      } catch (error) {
        this.$modal.show('recover-password-error')
        this.$modal.hide('recover-password')
        this.isLoadingAuth = false
        console.log({ error })
      }
    },
    tryAgainRecoverPassword () {
      this.$modal.hide('recover-password-error')
      this.$modal.show('recover-password')
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    .icon-content {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 30px 0;
      .icon {
        display: flex;
        width: 90px;
        height: 90px;
      }
    }

    .text {
      font-size: 20px;
      color: #404040;
      text-align: center;
      padding: 0 30px;
      &.email {
        font-weight: 800;
      }
    }
    .button {
      padding: 10px;
      background: blue;
      color: white;
      width: 60%;
      border-radius: 50px;
    }
  }

</style>
